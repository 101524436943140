.cardContainer {
  max-width: 195px;
  background: #F8F8F8;
  border-radius: 8px;
  padding: 0 10px;
}

.cardImage {
  width: 50px;
}

.bankImage {
  width: 50px !important;
  height: 36px !important;
}

.achCard {
  padding: 6px 0px;
}
