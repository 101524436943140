.snackbar {
  width: 575px;
  max-width: 90%;
  margin: 0 auto 10px;

  .secondarybutton {
    font-weight: 400;
    background: rgba(255, 255, 255, 0.7);
    color: var(--theme-highlight-color);
    &:hover {
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 12px;
      color: var(--theme-highlight-color);
    }
  }

  .primarybutton {
    background: var(--theme-cotrast-color);
    color: var(--theme-highlight-color);
    font-weight: 400;
    &:hover {
      border-radius: 12px;
      background: var(--theme-cotrast-color);
      color: var(--theme-highlight-color);
    }
  }
}

.container {
  padding: 10px 15px;
  color: var(--theme-cotrast-color);
  border-radius: 12px;
  background: linear-gradient(
    95.69deg,
    var(--theme-highlight-color) 4.34%,
    var(--theme-highlight-color-bg) 55.62%,
    var(--theme-highlight-color-2) 91.79%
  );
}
.buttons {
  margin-block: auto;
}
