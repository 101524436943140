.barContainer {
  width: 100%;
  height: 300px;
}

.customTooltip {
  background: #fff;
  padding: 10px;
  min-width: 150px;
  max-width: 320px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);
}

.customRenewalTooltip {
  max-width: fit-content;
}

.toolTipItem {
  width: 300px;
  overflow: hidden;
}

.toolTipViewMore {
  margin-top: 18px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.toolTipValue {
  display: flex;
}

.toolTipName {
  display: inline-block;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 3px;
}

.renewalTooltipList {
  width: 300px;
  overflow: hidden;
  padding: 0px 5px !important;

  .renewalTooltipItem {  
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    padding: 0,
  }

  .renewalTooltipText {
    width: 94%;
    display: inline-flex;
    justify-content: space-between;
  }
}
