.header-appbar {
  .MuiOutlinedInput-root {
    padding: 0px 14px !important;
  }
}


.MuiAutocomplete-popper {
  .MuiAutocomplete-paper {
    margin-top: 0px !important;
  }

  .MuiAutocomplete-noOptions {
    display: none !important;
  }

  .MuiAutocomplete-groupLabel {
    padding-left: 16px !important;
    font-size: 14px !important;
    line-height: 40px !important;
  }

  .MuiAutocomplete-option {
    padding-left: 16px !important;
  }
}