.categoryHeader {
  width: 25%;
}

.nameHeader {
  width: 30%;
}

.dateHeader {
  width: 30%;
}

.buttonContainer {
  min-width: 75px;
}

.tableColumn {
  cursor: pointer;
}

.smPaper {
  max-width: 490px;
}