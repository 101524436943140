.imageContainer {
  width: 120px;
  height: 100%;
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
  margin: 0 15px 0 0;
}

.uploadImage {
  max-width: 100%;
  max-height: 100%;
}

.closeBtn {
  position: absolute !important;
  top: -10px;
  right: -10px;
}
