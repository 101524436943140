.inputColumn {
  width: 5rem;
}

.longColumn {
  width: 15rem;
}

.mediumColumn {
  width: 6rem;
}

.iconColumn {
  width: 2rem;
}

.errorText {
  margin: 10px 0 0;
}

.deleteIconColor {
  color: var(--theme-highlight-color)!important;
}
