.selectField {
  width: 200px;
}

.inputField {
  width: 200px;
}

.deleteIconColor {
  color: var(--theme-highlight-color)!important;
}


.inputColumn {
  width: 20%
}