.dropzone {
  border: 2px dotted rgb(179, 177, 177);
  border-radius: 5px;
  padding: 15px;

  .highlightedText {
    color: #2fb6f9;
    cursor: pointer;
  }

  .guidelineText {
    color: rgb(179, 177, 177);
  }
}
