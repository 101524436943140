.dialogTitle {
  background: linear-gradient(95.69deg, var(--theme-highlight-color) 4.34%, var(--theme-highlight-color-bg) 55.62%, var(--theme-highlight-color-2) 91.79%);
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--theme-cotrast-color);
}

.dialogContent {
  padding: 30px !important;
}

.dialogActions {
  padding: 15px 30px !important;
}

.closeIcon {
  color: var(--theme-cotrast-color);
}

.alignCenter {
  justify-content: center !important;
}

.spinner {
  margin: 7px 0;
}

.noPadding {
  padding: 20px 0 !important;
}
