.headerSwitch {
  margin: 0 0 0 20px;
}

.accordionWrapper {
  padding: 24px 28px 28px;
}

.accordianBackground {
  background: #F5F4F680 !important;
}

.detailsContainer {
  width: 100%;
  padding: 24px;
}

.accordionIcon {
  order: -1;
  margin-right: 0px;
}

.deleteIconColor {
  color: var(--theme-highlight-color)!important;
}