.dividerContainer {
  position: relative;

  &.spacing-1 {
    margin: 8px 0px;
  }

  &.spacing-1_5 {
    margin: 12px 0px;
  }

  &.spacing-2 {
    margin: 16px 0px;
  }

  &.spacing-2_5 {
    margin: 20px 0px;
  }

  &.spacing-3 {
    margin: 24px 0px;
  }

  &.spacing-3_5 {
    margin: 38px 0px;
  }

  &.spacing-4 {
    margin: 42px 0px;
  }

  .dividerText {
    position: absolute;
    background: #FFF;
    top: 50%;

    &.alignCenter {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0px 12px;
    }

    &.alignLeft {
      left: 0;
      transform: translate(0%, -50%);
      padding-right: 12px;
    }

    &.alignRight {
      right: 0;
      transform: translate(0%, -50%);
      padding-left: 12px;
    }
  }

  .divider {
    width: 100%;
    border-bottom: 1px solid rgba(40, 49, 64, 0.2);
  }
}