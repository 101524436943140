.fabIcon {
  background: linear-gradient(95.69deg, var(--theme-highlight-color) 4.34%, var(--theme-highlight-color-bg) 55.62%, var(--theme-highlight-color-2) 91.79%) !important;
  box-shadow: none !important;
}

.iconColor {
  color: var(--theme-cotrast-color);
}

.accordionDetailsContainer {
  margin-top: 24px;
}

.accordionWrapperRoot {
  border: 1px solid #eaf1f8;
  border-radius: 20px !important;
  padding: 10px;
  &::before {
    height: 0;
  }
}
