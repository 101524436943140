.FilePreviewSection {
  border: 2px solid rgb(192, 192, 192);
  border-radius: 15px;
  padding: 0.5rem;

  .removeWrapper {
    display: flex;
		align-items: center;
    justify-content: flex-end;
  }

  .removeText{
    color: #2fb6f9;
    cursor: pointer;
		padding-right: 1rem;
  }

  .centeredComponent{
    min-height: 100%;
    padding-right:5px;
  }
}