.upload_section {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 32px 20px;
}

.upload_content {
  display: block;
  text-align: center;
}

.upload_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 32px 20px;
}
