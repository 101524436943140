.table_list_container {
  overflow: hidden;
}

.table_search_bar_container {
  padding: 24px 24px 5px;
}

.table_list_header_container {
  padding: 20px 32px;
  background: var(--theme-table-header);
}

.table_list_body_container {
  padding: 10px 32px 20px;
}
