.iconWrapper {
  height: 125px;
  width: 125px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.23);

  &.noBorder {
    border: 0;
  }
}

.fileInput {
  display: none;
}

.uploadedImage {
  max-width: 100%;
  max-height: 100%;
  border-radius: 12px;
}

.subTitle {
  transition: all 0.3s;
  font-weight: 500;
}
