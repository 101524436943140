.titleContainer {
  padding: 51px 44px;
  position: relative;
}

.title {
  font-size: 36px;
  max-width: 475px;
}

.subTitle {
  font-weight: normal;
  padding-top: 24px;
  margin-bottom: 12px;
}

.infoIcon {
  margin-left: 5px; 
}

.radioColumn {
  width: 10px;
  padding-left: 22px !important;
  padding-right: 10px !important;
}

.idColumn {
  width: 200px;
}

.tableWrapper {
  min-height: 10rem;
}

.tableColumns {
  padding: 6px !important;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.searchIcon {
  cursor: pointer;
}

.tableTitle {
  bottom: 0;
  position: absolute;
}

.button {
  min-width: 200px;
  font-weight: normal;
}

.gifImage {
  height: 350px;
}

.tickIcon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.closeIcon {
  position: absolute !important;
  right: 2rem;
  top: 2rem;
  z-index: 101;
}