.header.primaryAppBar {
  background: #fff;
  padding: 24px 32px 20px;
  border-bottom: 1px solid #cecbde;

  .logo {
    height: 32px;
  }

  .searchWrapper {
    padding-left: 70px;

    @media screen and (max-width: 800px) {
      padding-left: 20px;
    }

    @media screen and (max-width: 600px) {
      padding: 0;
    }
  }

  .globalAutoComplete {
    max-width: 350px;
  }

  .globalSearch {
    background: #f8f8f8;
  }

  .searchTextfield {
    height: 45px;
  }

  .userName {
    font-weight: 700;
  }

  .termsContainer {
    padding: 0;
    margin-left: 16px;
  }

  .termsHeaderButton {
    padding: 12px;
    background-color: #F2EEF866;
    border-radius: 8px;
  }
}

.termItems {
  text-decoration: none;
  color: black;
}

.searchDescription {
  padding: 2px 2px 2px 5px;
  background: var(--theme-table-header);
  border-radius: 5px;
}

.searchExample {
  text-align: right;
}