
.headerSwitch {
  margin-left: 20px;
}


.totalValue {
  margin: 0 0 0 10px;
}

.helperTextPosition {
  margin-left: 16px;
}