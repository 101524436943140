/* Font -  Inter Display */
@font-face {
  font-family: 'InterDisplay';
  src: url('../fonts/InterDisplay-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'InterDisplay';
  src: url('../fonts/InterDisplay-Medium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'InterDisplay';
  src: url('../fonts/InterDisplay-Bold.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
}
