.filterChip {
  margin: 10px 10px 0 0;
  border-radius: 16px !important;
  border-color: var(--theme-highlight-color) !important;
}

.deleteIcon {
  height: 16px !important;
  width: 16px !important;
  color: #292D32 !important;
  margin-right: 10px !important;
}
