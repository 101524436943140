// Reset CSS

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}

::placeholder {
  color: var(--placeholder);
  opacity: 1;
}

:-ms-input-placeholder {
  color: var(--placeholder);
}

::-ms-input-placeholder {
  color: var(--placeholder);
}

// Carousel Component Overrides
.react-multi-carousel-dot-list {
  .react-multi-carousel-dot button {
    width: 25px;
    height: 5px;
    border-radius: 5px;
    border-color: var(--theme-highlight-color);
  }

  .react-multi-carousel-dot--active button,
  .react-multi-carousel-dot button:hover:active {
    background: var(--theme-highlight-color);
  }
}

.dflex {
  display: flex;
  flex-wrap: wrap;
}

.button-wrapper {
  min-width: 150px !important;
}

.grey-spinner {
  margin: 4px 0;
  color: var(--theme-text-disabled);
}

.button-spinner {
  margin: 4px 0;
  color: var(--theme-cotrast-color);
}

span.sucess-fields {
  position: absolute;
  right: 25px;
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.payments {
  label {
    border: 1px solid #c0c3c6cc;
    border-radius: 12px;
    padding: 18px;
  }
}

// dashboard
.dashboard_wrapper {
  background: var(--theme-background-alternate);
  width: 100%;
  height: calc(100vh - 0px);
  overflow-y: auto;
  top: 100px;
  padding: 140px 24px 65px;
  min-width: 360px;
  h1 {
    font-weight: 600;
    font-size: 30px;
    color: var(--theme-text-primary);
  }
}
// Schedule Training Wrapper
.schedule_training_wrapper {
  padding: 100px 0px;
  padding-bottom: 10px;
}

// info-wrapper
.info_wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  span {
    line-height: 0;
    padding-right: 10px;
    position: relative;
  }
  p {
    font-size: 18px;
    color: var(--theme-text-secondary);
    font-weight: normal;
  }
}
// info-wrapper

// loader
.subs_loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    z-index: -1;
  }
  img {
    max-width: 50px;
  }
}

// transfer-enable
.transfer-enable {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  span {
    margin-left: 20px;
    background: var(--theme-success);
    border-radius: 4px;
    color: var(--theme-cotrast-color);
    padding: 5px 10px;
    font-weight: normal;
    font-size: 14px;
  }
}
// transfer-enable

span.active-light {
  background: #38ada914;
  color: var(--theme-success);
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 13px;
}
// small-info-with-line

.menuIcons {
  color: var(--theme-highlight-color);
  margin-right: 8px !important;
}

.subsc_header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.accordionWrapper .MuiAccordionSummary-content {
  flex-direction: column;
  align-items: flex-start;
}
.benefits-cpt-section {
  .MuiAccordionSummary-root {
    min-height: unset;

    &.Mui-expanded {
      min-height: unset;
    }

    .MuiAccordionSummary-content, .MuiIconButton-root {
      margin: 0px;
      padding: 0px;
    }
  }
}
.product-section-header {
  .MuiFormLabel-colorSecondary {
    color: var(--theme-secondary-main);
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--theme-secondary-main);
  }
}

.subsc_location_add_delete_icon {
  background: var(--theme-highlight-color) !important;
  margin-left: 4px !important;
  width: 50px;
  height: 50px;
}
.subsc_location_plans {
  display: flex;
  flex-direction: column;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}

//global
.mgBottom8 {
  margin-bottom: 8px !important;
}

.mgBottom24 {
  margin-bottom: 24px!important;
}

.mgRight8 {
  margin-right: 8px !important;
}
.flexDirectionRow {
  flex-direction: row !important;
}
.displayFlex {
  display: flex;
}

.alignItemsBaseline {
  align-items: baseline !important;
}
.justifyContentSpaceBetween {
  justify-content: space-between;
}
.alignItemsCenter {
  align-items: center !important;
}
.bold {
  font-weight: 500 !important;
}
.summary_title {
  font-size: 18px !important;
}
.textAlignCenter {
  text-align: center !important;
}
.flexWrap {
  flex-wrap: wrap;
}
.colorGreen {
  color: var(--theme-success) !important;
}

.themeTextSuccess {
  color: var(--theme-text-success) !important;
}

.contentMainContainer {
  display: flex;
}

//Plan card
.cards_wrapper {
  margin: 0 -10px 30px;
  @media only screen and (max-width: 767px) {
    margin: 0 0 20px;
  }
}

// stripe-onboard
.stripe_onboard {
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 1024px) {
    flex-direction: column-reverse;
  }

  &_wrapper {
    width: 100%;
  }

  &_left {
    width: calc(100% - 425px);
    margin-right: 400px;
    padding: 50px;
    @media only screen and (max-width: 1024px) {
      width: 100%;
      margin-right: 0;
      padding: 30px 20px;
    }
    &_logo {
      max-width: 1200px;
      margin: 0 auto;
    }
    &_content {
      padding: 30px 0 0;
      max-width: 1200px;
      margin: 0 auto;
      
    }
  }
}

// Location-onboard 
.location_onboard_wrapper {
  width: 100%;
}

// Google Maps
.mapsWrapper {
  position: relative;
  height: 500px;
}

.maps-autocomplete {
  margin: 10px;
  padding: 10px;
  width: 300px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.pac-container {
  z-index: 9999;

  &:after {
    display: none;
  }
}
