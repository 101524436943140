.containers {
  .phoneInput {
    border: 0 !important;
    width: 100%;
    color: currentColor;
    font-size: inherit;
    line-height: 19px;
    padding-top: 0;

    &:focus {
      box-shadow: none !important;
    }
  }
}
