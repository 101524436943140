.sideNav {
  position: relative;
  height: 100vh;

  .collapseIcon {
    position: absolute;
    right: -25px;
    top: calc(50% + 40px);
    cursor: pointer;
    border-radius: unset;
    padding: 6px 0px;
    background-color: unset;

    &:hover {
      background-color: unset;
    }

    &.activeIcon {
      transform: rotate(180deg);
      -moz-transform: rotate(180deg);
    }
  }
}

.drawer {
  background: #f7f5fb;
  width: 90px;
  transition: all 300ms;

  .drawerPaper {
    width: 100%;
    height: auto;
    position: absolute;
    border: 0;
    top: 99px;
    bottom: 0;
    background: var(--theme-primary-main);
    z-index: 1200;
    overflow: hidden auto;
    justify-content: space-between;
    padding: 20px;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .avatar {
    width: 24px;
  }

  .subList {
    max-height: 0;
    padding: 0 0px 0 5px;
    margin: 0;
    overflow: hidden;
    transition: all 0.3s;
  }

  .listAvatar {
    min-width: 56px;
    transition: all 0.3s;
  }

  .listAvatarActive {
    display: none;
  }

  .listItemText {
    color: '#C0AED9';
    width: 160px;
    flex-shrink: 0;
  }


  .productItemText {
    display: inline-flex;
    margin-right: 16px;
    max-width: fit-content;
  }

  &.activeDrawer {
    width: 300px;

    .subList {
      max-height: 500px;
    }

    .listAvatar {
      min-width: 45px;
    }

    .navFooter {
      display: block;
      transition: all 0.3s;
      bottom: 0;
    }
  }

  .list {
    color: var(--theme-cotrast-color);

    .listItem {
      padding-left: 14px;

      &.activeItem {
        border-radius: 12px;
        background: linear-gradient(95.69deg,
            var(--theme-highlight-color) 4.34%,
            var(--theme-highlight-color-bg) 55.62%,
            var(--theme-highlight-color-2) 91.79%);

        .listAvatar {
          display: none;
        }

        .listAvatarActive {
          display: inline-flex;
        }
      }
    }
  }
}

.navFooter {
  display: none;

  .navLink {

    a,
    span {
      color: var(--theme-cotrast-color);
      cursor: pointer;
    }

    span {
      padding: 0px 6px;
    }
  }
}

.productDivider {
  width: 100%;
  background-color: var(--theme-cotrast-color) !important;
  margin: 0px 0px 12px !important;
}

.divider {
  width: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) !important;
  margin: 10px 0px 12px !important;
}

.popoverCont {
  padding: 8px;
}

.popoverItem {
  display: flex;
  padding: 12px;

  .imageItem {
    display: flex;
    padding-right: 12px;
  }
}