.sendgrid_ids {
  width: 30%;
  padding: 16px;
}

.updated_by,
.updated_at {
  width: 20%;
}

.sendgrid_data {
  max-width: 500px;
  padding: 0px;
  cursor: pointer;
}

.icons {
  width: 10%;
}

.tableColumn {
  cursor: pointer;
}