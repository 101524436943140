
.upfrontWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 0 !important;
}

.upfrontCopy {
  padding-bottom: 0 !important;
}

.pricingContainerHeading {
  background: var(--theme-background-variant1);
  padding: 9px;
}