.chipContainer {
  border-radius: 8px !important;

  &.green {
    background-color: #38ada914;
    color: var(--theme-success);
  }
  &.red {
    background-color: #ff63480d;
    color: var(--theme-error);
  }
  &.grey {
    background-color: #f0f0f0;
    color: gray;
  }
  &.blue {
    background-color: #1e90ff14;
    color: #1e90ff;
  }
}
