.paymentModeTitle { 
  max-width: 190px !important;
}

.toggleTitle {
  max-width: 190px !important;
}

.actionContainer {
  position: absolute;
  top: 20px;
  right: 53px;
}
